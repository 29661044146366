import React from "react"

import { Container } from 'reactstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider-aplication'
import worldIcon from '../assets/images/world.png' 
import truckAbsolute from '../assets/images/credit-truck.png' 

export default () => (
  <Layout>
    <SEO title="Credit Application" />
    <Slider title="CREDIT APPLICATION"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<h4 className="surpassing"><b>SURPASSING</b> ALL BOUNDARIES FROM <b>US</b> TO <b>YOU</b></h4> 
			  </div>
			</div>
		</Container>
	</div>
	<div className="point-crossed-background2" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="credtiH">{'CREDIT APPLICATION'}</h4>  
				<img className="truckAbsolute4" src={truckAbsolute} alt="truck"/> 
			  </div> 
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"> 
				<div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 whiteBack">  
					<p className="grayText2">{'Download the document shown below and send it to Quotes@gutierreztrucking.com with the required information.'}</p> 
					<a href="#" className="locationVisit left">{'Document'}</a> 
				</div>
			  </div> 
			</div>
		</Container>
	</div> 
  </Layout>
)
